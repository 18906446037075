import { useState } from "react";

// Checks the VITEST_WORKER_ID variable (set by vitest) to determine if the code is running in a test environment
// Only in the test environment does the process.env exist, so we have to try/catch it
export const useIsInTest = () => {
  const [isInTest] = useState<boolean>(() => {
    try {
      return process?.env?.VITEST_WORKER_ID !== undefined;
    } catch {
      return false;
    }
  });

  return isInTest;
};
