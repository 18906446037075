import { RefObject, useEffect, useState } from "react";
import { useIsInTest } from "./useIsInTest";

// Returns the DOMRect (position and size) of the element for the given ref
// Doesn't attempt to get the rect if the hook is running in a test
export const useBoundingClientRect = (ref: RefObject<HTMLElement>) => {
  const isInTest = useIsInTest();
  const [rect, setRect] = useState<DOMRect>(new DOMRect());

  useEffect(() => {
    const element = ref.current;

    if (element && !isInTest) {
      const observer = new ResizeObserver(() => {
        setRect(element.getBoundingClientRect());
      });

      observer.observe(element);

      return () => observer.disconnect();
    }
  }, []);

  return rect || new DOMRect();
};
