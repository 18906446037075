import { createContext, ReactNode, useMemo } from "react";
import { useFeaturesGetQuery } from "../../api/clients/user-profile-api-client";
import { Feature } from "../../api/user-profile/codegen";

export const FeatureFlagContext = createContext<{
  featureFlags: Feature[] | undefined;
}>({ featureFlags: [] }); // default values

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const { data: featureFlags, isLoading, isFetching } = useFeaturesGetQuery();

  const contextValue = useMemo(() => ({ featureFlags }), [featureFlags]);

  if (!isLoading && !isFetching) {
    return (
      <FeatureFlagContext.Provider value={contextValue}>
        {children}
      </FeatureFlagContext.Provider>
    );
  }
};
