import { useEffect, useState } from "react";

// Returns the current window height and width. onResize parameter
// will be called as part of the resize event listener, if provided.
export const useWindowSize = (onResize?: () => void) => {
  const getWindowSize = () => ({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [size, setSize] = useState(getWindowSize());
  useEffect(() => {
    const handleResize = () => {
      setSize(getWindowSize());
      onResize && onResize();
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return size;
};
