import {
  ABIBreadcrumb,
  LightOrDark,
  useURLPath,
} from "@ab-inbev-labs/ux-react-components";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { LayoutSize, useLayoutSize } from "../hooks/useLayoutSize";
import { useBoundingClientRect, useWindowSize } from "@ab-inbev/sam-utils";

type CollapsibleBreadcrumbProps = {
  isThisRenderedInvisibleOnWebpagePrint: boolean;
};

const generateBaseBreadcrumb = (path: string, t: (key: string) => string) => {
  const baseBreadcrumb = [];
  if (path !== "/apps" && path !== "/") {
    baseBreadcrumb.push({ label: t("url.apps"), to: "/apps" });
  }
  return baseBreadcrumb;
};

// Breadcrumb will be constrained to viewport width minus this value. Chosen somewhat arbitrarily, just
// needs to be high enough to prevent the breadcrumb from clipping under the user profile elements.
const BREADCRUMB_COLLAPSE_THRESHOLD = 350;

const CollapsibleBreadcrumb: FC<CollapsibleBreadcrumbProps> = ({
  isThisRenderedInvisibleOnWebpagePrint,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const layoutSize = useLayoutSize();
  const urlBreadcrumb = useURLPath({ capitalize: false });
  const breadcrumbRef = useRef<HTMLDivElement>(null);
  const [collapsedLinks, setCollapsedLinks] = useState(0);
  const resetCollapsedLinks = () => setCollapsedLinks(0);
  const { width: breadcrumbWidth } = useBoundingClientRect(breadcrumbRef);
  const { width: viewportWidth } = useWindowSize(resetCollapsedLinks);

  // Whenever the window width changes (incl. on load), determine how many items of the breadcrumb need to be collapsed.
  // If the breadcrumb is currently too wide, collapse one more item. This will cause a re-render and change the width
  // of the breadcrumb, which will trigger this effect again, until the breadcrumb is no longer too wide.
  useEffect(() => {
    if (breadcrumbWidth > viewportWidth - BREADCRUMB_COLLAPSE_THRESHOLD) {
      setCollapsedLinks((c) => c + 1);
    }
  }, [breadcrumbWidth, viewportWidth]);

  // Whenever the URL changes, reset the collapsed links to re-run the collapse logic.
  // Collapse state is also reset whenever the window width changes via the useWindowSize hook.
  useEffect(() => {
    resetCollapsedLinks();
  }, [location.pathname]);

  const breadcrumbItems = useMemo(() => {
    // Start with the 'Apps' link, if necessary
    const baseBreadcrumb = generateBaseBreadcrumb(location.pathname, t);

    // Get the full list of links based on current URL
    const fullBreadcrumb = [
      ...baseBreadcrumb,
      ...urlBreadcrumb.map((urlItem) => ({
        ...urlItem,
        label: t(`url.${urlItem.label}`, { defaultValue: urlItem.label }),
      })),
    ];

    // If links need to be collapsed, keep the first two ('Apps' and the current app's home).
    // Replace the collapsed links with an ellipsis, then keep the remaining items.
    const itemsBeforeEllipses = 2;
    if (collapsedLinks > 0) {
      return fullBreadcrumb
        .slice(0, itemsBeforeEllipses)
        .concat({
          label: "...",
          to: "",
        })
        .concat(fullBreadcrumb.slice(itemsBeforeEllipses + collapsedLinks));
    } else {
      return fullBreadcrumb;
    }
  }, [collapsedLinks, location.pathname, t, urlBreadcrumb]);

  return (
    layoutSize !== LayoutSize.Mobile && (
      <div className="breadcrumb-wrapper" ref={breadcrumbRef}>
        <ABIBreadcrumb
          separator=">"
          theme={LightOrDark.Light}
          className={isThisRenderedInvisibleOnWebpagePrint ? "no-print" : ""}
        >
          {breadcrumbItems.map(({ to, label }, i) => {
            if (!to) {
              // If no link (ellipsis when collapsed), don't render as a link
              return label;
            } else if (i === breadcrumbItems.length - 1) {
              // Don't want the current page to be a link, but still needs to be an anchor to be styled correctly
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              return <a key={to}>{label}</a>;
            } else {
              // All other items are regular links
              return (
                <Link key={to} to={to}>
                  {label}
                </Link>
              );
            }
          })}
        </ABIBreadcrumb>
      </div>
    )
  );
};

export default CollapsibleBreadcrumb;
